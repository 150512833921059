@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.high-team-body {
  background: #000 !important;

  .link {
    color: #fff!important;
  }

  .link_atual {
    color: #FFFF00 !important;
  }

  .title {
    color: #fff !important;
  }

  p {
    color: #fff !important;
  }

  a {
    color: #FFFF00 !important;
  }

  figcaption {
    color: #fff;
  }

  h2 {
    color: #fff !important;
  }

  span {
    color: #FFFF00;
  }
}

.team-body {
    background: #fff;
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
    width: 100%;
    
    .content-container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
  
      .link-container {
        font-size: 14px;
        font-family: 'Open Sans', "sans-serif";
        font-weight: 400;
        text-align: left;
        padding-bottom: 44px;
        display: flex;
        
        .link {
          text-decoration: none;
          color: #525252;
        }

        .link_atual {
          text-decoration: none;
          color: #31AEC4;
          padding-left: 5px;
        }
      }
    }

    .descricao {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 50px;
      text-align: left;
      padding-bottom: 50px;

      .descricao-text {
        .title {
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 36px;
          color:#31AEC4;
          padding-left: 0px;
        }
        
        p {
          font-family: 'Open Sans',  "sans-serif";
          font-weight: 400;
          font-size: 16px;
          line-height: 136.18%;
          align-items: center;
          color: #1B1B1B; 
        }

        a {
          font-family: 'Open Sans', "sans-serif";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 136.18%;
          align-items: center;
          text-decoration-line: underline;
          color: #2A95A8;
          padding-left: 6px;
        }
      }
    }
    
    .linhas-pesquisa {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      text-align: center;
      
      .title {
        text-align: left;
        font-family: 'Open Sans', "sans-serif";
        font-weight: 700;
        font-size: 24px;
        color:#31AEC4;
      }

      .linhas-pesquisa-imgs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 96px;
      }
    }

    .coordenation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;
      text-align: center; 

      .title {
        text-align: left;
        font-family: 'Open Sans', "sans-serif";
        font-weight: 700;
        font-size: 24px;
        color:#31AEC4;
        padding-bottom: 24px;
        padding-left: 0px;

        .coordenation-titles {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

  @media screen {
    @media (min-width: 1440px) {
      padding-left: 110px;

      .back-arrow {
        display: none;
      }

      .linhas-pesquisa-imgs{
        flex-direction: row;
        padding-right: 80px;
      }

      .coordenation-titles{
        display: flex;
        flex-direction: row;
        column-gap: 140px;

        .coord-title{
          .coordenator-name{
            font-family: 'Open Sans', 'normal';
            font-weight: 600;
            font-size: 16px;
            color: #1B1B1B;
            padding-right: 40px;
          }
          .coordenator-post{
            font-family: 'Open Sans', 'normal';
            font-weight: 400;
            font-size: 16px;
            color: #1B1B1B;
            padding-right: 40px;

            a {
              position:absolute;
              padding-left: 10px;
            }
          }
        }
      }

      .researchers{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
          padding-left: 0px;
        }
        .researchers-title{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 350px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .researcher-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      .discentes{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
          padding-left: 0px;
        }
        .discentes-titles{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 290px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .discente-name{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
          .discente-formation{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      .collaborators{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
          padding-left: 0px;
        }
        .collaborators-titles{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 390px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .collab-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }

    @media (min-width: 600px) and (max-width: 1439px) {
      padding-left: 60px;


      .home-link {
        display: none;
      }

      .linhas-pesquisa-imgs{
        flex-direction: column;
        padding-right: 80px;
      }

      .coordenation-titles{
        display: flex;
        flex-direction: row;
        column-gap: 30px;

        .coord-title{
          .coordenator-name{
            font-family: 'Open Sans', 'normal';
            font-weight: 600;
            font-size: 16px;
            color: #1B1B1B;
          }
          .coordenator-post{
            font-family: 'Open Sans', 'normal';
            font-weight: 400;
            font-size: 16px;
            color: #1B1B1B;

            a {
              position:absolute;
              padding-left: 10px;
            }
          }
        }
      }

      .researchers{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }
        .researchers-title{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 150px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .researcher-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      .discentes{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }
        .discentes-titles{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 90px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .discente-name{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
          .discente-formation{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      .collaborators{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }
        .collaborators-titles{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 190px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .collab-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: 599px) {
      padding-left: 50px;

      .home-link {
        display: none;
      }

      .linhas-pesquisa-imgs{
        flex-direction: column;
        padding-right: 40px;
      }

      .coordenation-titles{
        flex-direction: column;

        .coord-title{
          .coordenator-name{
            font-family: 'Open Sans', 'normal';
            font-weight: 600;
            font-size: 16px;
            color: #1B1B1B;
            padding-right: 40px;
          }
          .coordenator-post{
            font-family: 'Open Sans', 'normal';
            font-weight: 400;
            font-size: 16px;
            color: #1B1B1B;
            padding-right: 40px;
          }
        }
      }  
      
      .researchers{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }
        .researchers-title{
          display: grid;
          column-gap: 200px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .researcher-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      .discentes{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }

        .discentes-titles{
          display: grid;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .discente-name{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }

          .discente-formation{
            text-decoration: none;
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .collaborators{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;

        .title{
          text-align: left;
          font-family: 'Open Sans', "sans-serif";
          font-weight: 700;
          font-size: 24px;
          color:#31AEC4;
        }

        .collaborators-titles{
          display: grid;
          column-gap: 200px;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
    
          .collab-name{
            text-align: left;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }   
  }
}
  