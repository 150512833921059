.high-check {
  span {
    color: #fff !important;
  }
}

.high-buttons {
  .save {
    color: #FFF000 !important;
    background: #000 !important;
    border: 2px solid #FFF000 !important;
  }

  .post {
    background: #FFF000 !important;
    color: #000 !important;
  }
}

.high-search-button {
  background: #000 !important;
  color: #FFF000 !important;
  border: 1px solid #FFF000 !important;
}

.high-send-button {
  background: #FFF000 !important;
  color: #000 !important;
}

.check {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 8px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 136.18%;
    color: #1B1B1B;

    padding-left: 5px;
  }
}

.buttons {
  display: flex;
  column-gap: 20px;
  padding-top: 50px;
  padding-left: 200px;

  .save,
  .post {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 12px;
    gap: 4px;
    height: 45px;

    border-radius: 4px;

    cursor: pointer;
  }

  .save {
    color: #27486C;
    background: #fff;
    border: 2px solid #27486C;
    width: 130px;
  }

  .post {
    background: #27486C;
    border: none;
    color: #fff;
    width: 80px;
  }
}

.upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;

  .send-button {
    background: #27486C;
    color: #fff;
    width: 110px;
    height: 30px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #27486C;
    background: #fff;
    border: 2px solid #27486C;
    width: 110px;
    font-size: 14px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }

  .input-button {
    border: 2px solid "red";
  }

  .file-name {
    font-size: 14px;
  }
}

@media screen {
  @media (max-width: 599px) {
    .buttons {
      padding-left: 30px;
    }

    .upload {
      flex-direction: column;
      align-items: flex-start;

    }
  }
}

.close-button {
  float: right;
  border: none;
  background: none;
  cursor: pointer;
}

.high-file-name {
  color: #fff;
}