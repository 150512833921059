.high-acessibility-container {
  background: #000;
  color: #fff;

  .route {
    p {
      color: #FFFF00 !important;
    }

    a {
      color: #fff !important;
    }
  }

  .title {
    color: #FFFF00 !important;
  }

  .green,
  .pink {
    color: #fff !important;
    font-weight: bold;
  }
}

.acessibility-container {
  .route {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 120px;
    padding-top: 30px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;
      text-decoration: none;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;

      color: #31AEC4;
      padding-left: 4px;
    }
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 144%;

    color: #31AEC4;

    display: flex;
    padding-left: 120px;
    padding-top: 30px;
  }

  .text {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 25px 120px 80px 120px;
    font-size: 16px;
    line-height: 136.18%;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .green {
    color: #31AEC4;
  }

  .pink {
    color: rgba(253, 75, 176, 1);
  }

  @media screen {
    @media (max-width: 599px) {

      .route,
      .title {
        padding-left: 50px;
      }

      .text {
        padding: 25px 50px 50px 50px !important;
      }
    }
  }
}