.high-home-container {
  background: #000 !important;

  .title-container {
    h1 {
      color: #fff !important;
    }
  }

  .plataforms-container,
  .mapforPR-box,
  .realization-box,
  .publications,
  .schedule {

    p,
    h1 {
      color: #fff !important;
    }

    .plataforms-button {
      color: #FFFF00 !important;
      border: 2px solid #FFFF00 !important;
    }
  }

  .more-publications {
    padding-top: 30px;
    a {
      color: #FFFF00 !important;
    }
  }

  .high-box-container {
    column-gap: 50px !important;
    align-items: center !important;

    .plataform-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 40px 32px;
      gap: 48px;

      border: 2px solid #fff !important;
      border-radius: 4px;

      width: 344px;
      height: 280px;

    }
  }

  .mapforPR-box {
    padding-left: 685px !important;

    .mapforPR {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 32px;
      gap: 48px;

      border: 2px solid #fff !important;
      border-radius: 4px;

      width: 290px;
      height: 280px;
    }
  }

  @media (min-width: 1601px) {
    .mapforbr {
      margin-left: 111%;
      margin-top: 50px;
    }
  }

  @media (min-width: 1201px) and (max-width: 1600px) {
    .mapforbr {
      margin-left: 112%;
      margin-top: 50px;
    }
  }

  @media (min-width: 600px) and (max-width: 1200px) {
    .schedule-boxes {
      padding-top: 10px !important;
      padding-bottom: 150px !important;
    }
  }

  @media (max-width: 599px) {
    .plataforms-container {
      padding-left: 50px !important;
    }
    
    .boxes-container { 
      .edition-options {
        padding-left: 25px;
      }
      .posts {
        display: grid;
        justify-content: flex-start !important;
        grid-template-rows: repeat(3, 400px) !important;
      }
    }
  }
}

.home-container {
  overflow-x: hidden;
  padding-bottom: 90px;

  .banner {
    background: #FECB3B;
  }

  .central-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //padding-top: 30px;

    .title-container {
      .title-box {
        h1 {
          font-style: normal;
          font-weight: bolder;
          font-size: 56px;
          line-height: 20px;
          color: #1B1B1B;

          display: flex;
        }
      }

      .subtitle-box {
        padding-top: 5px;

        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #1B1B1B;

          display: flex;
          text-align: left;
        }
      }

      .show-more-box {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 10px;
        color: #1B1B1B;

        display: flex;
        padding-top: 90px;
        align-items: center;

        img {
          padding-left: 5px;
        }
      }
    }

    .carousel-container {
      width: 700px;
      height: 750px;
      padding-right: 90px;

      .control-arrow,
      .control-next {
        display: none;
      }
    }
  }

  .plataforms-container {
    display: grid;
    width: 100%;
    align-items: first baseline;

    .plataform-box {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
      width: 65%;

      div {
        align-items: start;

        h3 {
          font-style: normal;
          font-weight: bolder;
          font-size: 15px;
          line-height: 20px;
          color: #1B1B1B;

          display: flex;

        }
      }
    }

    .plataforms-button {
      text-decoration: none;
      color: #31AEC4;
      font-weight: 400;
      font-size: 14px;

      padding: 8px 5px 8px;
      gap: 4px;
      width: 140px;
      height: 20px;
      border: 2px solid #31AEC4;
      border-radius: 4px;
    }
  }

  .realization-box {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-top: 80px;
    width: 100%;
    justify-content: center;

    img {
      padding-right: 10px;
      width: 70px;
    }
  }

  .publications {
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 144%;
      color: #1B1B1B;
    }
  }

  .boxes-container {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    gap: 20px;
    .posts {
      display: grid;
      grid-template-columns: repeat(3, 370px);
      justify-content: space-between;
    }
  }

  .more-publications {

    .show-more {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      padding-left: 82%;
      padding-top: 50px;
    }

    a {
      font-style: normal;
      font-weight: bolder;
      font-size: 15px;
      line-height: 20px;
      color: #31AEC4;
      text-decoration: none;
    }
  }

  .schedule {
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 144%;
      color: #1B1B1B;
    }
  }

  .schedule-boxes {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    padding-top: 50px;
  }

  @media screen {
    @media (min-width: 1601px) {
      .central-container {
        .title-container {
          padding-left: 230px;
        }

        .subtitle-box {
          width: 70%;
        }
      }

      .boxes-container {
        justify-content: center;
      }

      .schedule-boxes {
        padding-left: 290px;
      }

      .mapforbr {
        padding-top: 100px;
        padding-left: 99%;
      }

      .plataforms-container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        padding-left: 150px;
        //padding-top: 200px;
      }
    }

    @media (min-width: 1201px) and (max-width: 1600px) {
      .central-container {

        .title-container {
          padding-left: 180px;
          padding-top: 10%;
        }

        .carousel-container {
          padding-top: 40px;
          padding-right: 20px;
        }
      }

      .plataforms-container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;

        padding-left: 150px;
        padding-top: 50px;
      }

      .mapforbr {
        padding-top: 100px;
        padding-left: 97%;
      }

      .boxes-container {
        justify-content: center;
      }

      .schedule-boxes {
        padding-left: 150px;
      }
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      .central-container {
        padding-top: 60px;
        .carousel-container {
          height: 350px;
        }

        .title-container {
          padding-left: 80px;
          //padding-top: 100px;

          .title-box {
            h1 {
              font-size: 40px !important;
            }
          }

          .subtitle-box {
            padding-top: 5px;

            h1 {
              text-align: left;
              line-height: 20px !important;
            }
          }

          .show-more-box {
            padding-top: 50px;
          }
        }
      }

      .plataforms-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        column-gap: 20px;
        row-gap: 60px;
        padding-left: 100px;
        padding-top: 70px;
      }

      .publications {
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
      }

      .boxes-container {
        justify-content: center;
        .posts {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 30px;     
        }
      }

      .show-more {
        padding-left: 80% !important;
        padding-top: 40px !important;
      }

      .schedule {
        padding-top: 80px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .schedule-boxes {
        column-gap: 15px;
        padding-top: 30px;
      }
    }

    @media (max-width: 599px) {
      .central-container {
        flex-direction: column-reverse;

        .title-container {
          padding-left: 10px;
          padding-top: 50px;

          .title-box {
            h1 {
              font-size: 54px;
            }
          }

          .show-more-box {
            padding-top: 50px;
          }
        }

        .carousel-container {
          width: 510px;
          height: 400px;
          padding-right: 20px;
        }
      }

      .plataforms-container {
        padding-top: 70px;
        padding-left: 80px;
        row-gap: 80px;
      }

      .publications {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .boxes-container {
        flex-direction: column;
        padding-top: 20px;
        row-gap: 15px;

        .posts {
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(1, 350px);
          gap: 30px;    
        }
      }

      .show-more {
        padding-left: 55% !important;
        padding-top: 40px !important;
      }

      .schedule {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .schedule-boxes {
        flex-direction: column;
        padding-top: 20px;
        padding-left: 20px;
        row-gap: 15px;
      }
    }

  }
}