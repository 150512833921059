.title {
  color: #31AEC4;
  padding-left: 10px;
  padding-top: 50px;
}

.high-title {
  color: #FFF000;
}

.text {
  font-size: 14px;
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.high-text {
  color: #fff !important;
}

.button {
  display: flex;
  padding-left: 10px; 
  padding-top: 20px; 
  .enter-button {
    padding: 10px 12px;
    gap: 4px;
    width: 89px;
    height: 40px;

    background: #31AEC4;
    color: #fff;

    border-radius: 4px;
    border: none;

    cursor: pointer;
  }

  .high-enter-button {
    background: #FFF000;
    color: #000;
  }
}
