.high-box-container {
  background: #000 !important;

  .individual-box {
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    position: relative;
    padding: 15px 25px;
    width: 300px;
    height: 380px;
  }

  .abstract {
    color: #FFFF00 !important;
  }

  .author,
  h4 {
    color: #FFFFFF !important;
  }

  .pink,
  .green,
  .yellow,
  .gray,
  .blue,
  .lightblue,
  .circle {
    background: #fff !important;
    color: #000 !important;
  }

  .option-button {
    background-color: #000 !important;
    border: 2px solid #FFFF00 !important;
    color: #FFFF00 !important;
  }
}

.box-container {
  width: 350px;
  height: 380px;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px 4px rgba(27, 27, 27, 0.1);
  border-radius: 4px;
  padding: 24px;

  .individual-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
    }

    .border-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      border-radius: 1000px;
    }

    .pink {
      background: #FEC9E7;
      color: #FD1598;
    }

    .green {
      background: #DBEAAE;
      color: #7A9628;
    }

    .yellow {
      background: #FFE8AA;
      color: #DDA201;
    }

    .blue {
      background: #A6C2DF;
      color: #27486C;
    }

    .lightblue {
      background: #A4DEE8;
      color: #2A95A8;
    }

    .gray {
      background: #6D6D6D;
      color: #FFFFFF;
    }

    .abstract {
      font-weight: 400;
      font-size: 16px;
      line-height: 136.18%;
      text-align: left;
    }

    .informations {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;

      .author {
        color: #31AEC4;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0px;
        margin-top: 0px;
        text-align: left;
        padding: 0px 0px 0px 0px;
      }

      .date-section {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;

        h4 {
          font-weight: 400;
          font-size: 12px;
          margin-top: 0px;
        }

        .circle {
          width: 4px;
          height: 4px;
          background: #1B1B1B;
        }
      }
    }

    .link {
      text-decoration: none;
      .option-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 12px;
        gap: 4px;

        width: 90px;
        height: 40px;

        border: 2px solid #31AEC4;
        border-radius: 4px;
        background-color: #FFFFFF;

        text-decoration: none;
        color: #31AEC4;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
      }
    }

  }

  @media (min-width: 600px) and (max-width: 1200px) {
    width: 340px;
    height: 410px;
  }
}