.posts-container {
  display: flex;
  flex-direction: row;
  column-gap: 120px;
  padding-left: 10px;

  .single-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .fild {
      color: #000;
      font-size: 16px;
      padding-bottom: 15px;
    }
  
    .text {
      color: #525252;
      list-style-type: none;
      padding: 0px 0px 20px 0px;
      font-size: 16px;
    }

    .icon-functions {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
    }
  }

  .icon-container {
    padding-top: 70px;
  }

  .delete-button {
    background: none;
    border: none;
  }

  @media (min-width: 600px) and (max-width: 1200px) {
    column-gap: 60px !important;

    .icon-functions {
      column-gap: 5px !important;
    }
  }

  @media (max-width: 600px) {
    column-gap: 10px !important;

    .icon-functions {
      column-gap: 5px !important;
    }

  
  }
}

.high-text {
  color: white;
}

.high-fild {
  color: #FFF000 !important;
}