.high-contrast-header {
  background: #000 !important;

  .high-contrast-container {
    background: #000;

    a {
      color: #FFFF00;
    }

    a:hover {
      color: #ffffff !important;
    }

    h1 {
      color: #FFFF00;
    }

    .high-contrast-button {
      color: #FFFF00;
      background: #000;
    }

    .high-contrast-button:hover {
      color: #ffffff
    }

    .high-contrast-component {
      background-color: #000;
    }

    .link-button {
      color: #FFFF00;
      background-color: #000 !important;
    }
  }

  .high-header-component {
    background: #000;

    .header-option {
      color: #FFFF00 !important;
    }

    .header-option:hover {
      color: #ffffff !important;
    }

    .high-selected {
      color: #ffffff !important;
      text-decoration: underline white !important;
    }

    .opened-menu {
      background: #000 !important;
      border-bottom: 2px solid #FFFF00;
      border-top: 2px solid #FFFF00;
    }
  }

  .high-selected {
    color: #ffffff !important;
    text-decoration: underline white !important;
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  background: #FFFFFF;
  cursor: default;
  width: 100%;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 90px;
  }

  a,
  .link-button {
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    color: #1B1B1B;
    column-gap: 30px;
  }

  a:hover {
    color: #31AEC4 !important;
  }

  .contrast-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    border: none;
    background-color: #fff;
    font-size: 11px;
    font-weight: 400;
    color: #1B1B1B;
    font-weight: 400;
    cursor: pointer;

  }

  .contrast-button:hover {
    color: #31AEC4;
  }

  h1 {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    color: #1B1B1B;
  }

  .selected {
    color: #31AEC4 !important;
  }

  .header-option:hover {
    color: #31AEC4;
  }

  .header-component {
    display: flex;
    flex-direction: row;
    width: 100%;

    .header-option {
      text-decoration: none;
      color: #172B40;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 136.18%;
      padding-left: 20px;
    }

    .lde-logo {
      padding-top: 15px;
      padding-left: 50px;
    }

  }

  .access-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 60px;
    width: 100%;
    border-bottom: 1px solid #D1D1D1;

    .access-sections {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 30px;
      padding-right: 150px;
    }

    .link-button {
      border: none;
      background: #fff;
      cursor: pointer;
    }

    .access-tools {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 30px;

      .links-container {
        display: flex;
        flex-direction: row;
        column-gap: 30px;

        .selected {
          text-decoration: underline;
        }
      }

      .access-tools-itens {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .opened-menu {
    background: #fff;
    position: fixed;
    overflow: hidden;
    z-index: 1;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-left: 1px solid rgba(232, 232, 232, 1);
    flex-direction: column;
    animation: nav-anim-bottom 0.2s;
    box-shadow: 4px 0px 10px rgba(140, 140, 140, 0.3);

    .links-grid {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-top: 200px;

      .header-option {
        padding-top: 20px;
        padding-bottom: 7px;
        text-align: left;
        font-size: 25px;
      }
    }

    .menu-icon {
      position: relative;
      width: 7%;
      align-items: end !important;
      justify-content: right !important;
      padding: 30px 10px 10px 0px;
      float: right;
      cursor: pointer;
    }
  }

  @media screen {
    @media (min-width: 1700px) {
      .access-sections-responsive {
        display: none;
      }

      .menu-icon {
        display: none;
      }
    }

    @media (min-width: 1201px) and (max-width: 1699px) {
      .access-sections-responsive {
        display: none;
      }

      .menu-icon {
        display: none;
      }
    }

    @media (min-width: 600px) and (max-width: 1200px) {

      .header-option {
        display: none !important;
      }

      .opened-menu {
        .header-option {
          display: block !important;
        }

        .menu-icon {
          width: 3% !important;
        }

        .links-grid {
          padding-left: 60px;
        }
      }


      .access-container {
        padding: 0px 40px;

        .access-sections-responsive {
          display: flex;
          flex-direction: row;
          align-items: 15center;
          column-gap: 30px;
          padding-right: 250px;
        }

        .access-sections {
          display: none;
        }

        .access-tools {
          column-gap: 20px;
          padding-left: 10px;

          .access-tools-itens {
            display: none;
          }
        }
      }

      .lde-logo {
        padding-top: 15px;
        padding-left: 20px;
      }
    }

    @media (max-width: 599px) {
      .header-options {
        .header-option {
          display: none;
        }
      }

      .menu-icon {
        cursor: pointer;
      }

      .opened-menu {

        .header-option {
          display: block;
        }
      }

      .access-sections-responsive {
        display: none;
      }

      .access-sections {
        display: none !important;
      }

      .access-tools-itens {
        display: none !important;
      }

      .lde-logo {
        padding-left: 20px !important;
      }

      .access-tools {
        gap: 10px !important;

        .links-container {
          align-items: center;
          column-gap: 20px !important;

        }
      }
    }
  }


}