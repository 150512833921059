.high-footer-container {
  background: #000 !important;

  .link {
    color: #FFFF00 !important;
  }

  p {
    color: #FFFFFF !important;
  }
}

.footer-container {
  overflow-x: hidden;
  background: #FFFFFF;
  cursor: default;
  border-top: 1px solid #D1D1D1;
  box-shadow: 4px 0px 12px rgba(140, 140, 140, 0.3);

  .options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 100px;
    justify-content: center;
    padding: 40px 20px 20px 20px;

    .link {
      font-weight: 400;
      font-size: 14px;
      color: rgba(23, 43, 64, 1);
      text-decoration: none;
      text-align: left;
    }
  }

  .footer-banner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    padding: 30px 150px 30px 150px;
    justify-content: space-between;

    .realization-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px !important;
    }

    .footer-logos {
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: row;
      gap: 30px;
    }

    p {
      color: #1A1A1A;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }

    img {
      width: 90%;
    }
  }

  .adm-area {
    .login-button {
      color: #000;
      background-color: #FFFF00;
    }
  }

  @media screen {
    @media (min-width: 1201px) and (max-width: 1600px) {
      .options-container {
        column-gap: 70px;
      }
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      .options-container {
        column-gap: 80px;
        row-gap: 20px;
        padding: 40px 20px 20px 20px;
      }

      .footer-banner {
        padding: 30px 50px 30px 50px;
      }
    
    }

    @media (max-width: 599px) {
      .options-container {
        display: grid;
        grid-template-columns: repeat(2, 100px);
        align-items: center;
        row-gap: 20px;
        column-gap: 70px
      }

      .footer-banner {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
        padding: 30px 0px 30px 0px;
        justify-content: center;
        gap: 30px;

        img {
          width: 80%;
        }
      }


    }

  }
}