.high-box-options {
  background: #000 !important;
  border: 1px solid #fff !important;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 15px;
  width: 40px;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px 4px rgba(27, 27, 27, 0.1);
  border-radius: 4px;
  border: 1px solid #fff ;

  img {
    width: 20px;
  }

  @media screen {
    @media(max-width: 599px) {
      flex-direction: row;
      column-gap: 10px;
      width: 110px;
      height: 20px;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
  }
}