@import url("https://fonts.googleapis.com/css?family=Open+Sans");

form {

  .high-input-field {
    p {
      color: #fff !important;
    }

    input {
      color: #fff !important;
      border: 1px solid #FFF000 !important;
      background-color: #000 !important;
    }
  }

  .high-textarea {
    p {
      color: #fff !important;
    }

    textarea {
      border: 1px solid #FFF000 !important;
      background-color: #000 !important;
      color: #fff !important;
    }
  }

  .high-botao {
    button {
      color: #000 !important;
      background-color: #FFF000 !important;
      border: none !important;
    }
  }

  p {
    margin-top: 0px;
    margin-bottom: 4px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.18%;
    color: #525252;
  }

  .input-field {
    margin-bottom: 16px;

    input {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;

      width: 100%;
      height: 39px;

      border: 1px solid #525252;
      border-radius: 4px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;
    }
  }

  .textarea-field {
    margin-bottom: 32px;
    width: 600px;

    textarea {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 14px 16px;

      resize: vertical;
      height: 180px;
      width: 100%;

      border: 1px solid #525252;
      border-radius: 4px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;
    }
  }

  .botao {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      text-align: center;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #ffffff;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      gap: 4px;
      width: 65px;
      height: 39px;
      background: #31aec4;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  @media (max-width: 599px) {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .input-field {
      input {
        padding: 10px 16px;

        width: 300px;
      }
    }

    .textarea-field {
      margin-bottom: 32px;
      width: 300px;
    }
  }
}