.high-team-container {
  background: #000;

  a {
    color: #fff !important;
  }

  h1 {
    color: #FFFF00 !important;
  }

}

.team-container {
  width: 100%;
  padding-bottom: 50px;

  .route {
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-top: 30px;

    .back-arrow {
      display: none;
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;
      text-decoration: none;
    }

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #31AEC4;
      text-decoration: none;
      padding-left: 5px;
    }
  }

  .title {
    display: flex;
    padding-left: 100px;

    h1 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 144%;

      color: #31AEC4;
    }
  }

  .team-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;

    .box1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 150px;
    }

    .box2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 150px;
    }
  }

  @media screen {
    @media (min-width: 600px) and (max-width: 1200px) {
      padding-bottom: 120px;

      .route {
        padding-left: 40px;

        .home-link {
          display: none;
        }

        .back-arrow {
          display: flex;
        }
      }

      .title {
        padding-left: 30px;
      }

      .team-box {
        padding-top: 100px;
        row-gap: 150px;

        .box1 {
          column-gap: 80px !important;
        }

        .box2 {
          column-gap: 80px !important;
        }
      }
    }

    @media (max-width: 599px) {
      .route {
        padding-left: 40px;

        .home-link {
          display: none;
        }

        .back-arrow {
          display: flex;
        }
      }

      .title {
        padding-left: 30px;
      }

      .team-box {
        row-gap: 80px;

        .box1 {
          flex-direction: column !important;
          row-gap: 80px;
        }

        .box2 {
          flex-direction: column !important;
          row-gap: 80px;
        }
      }
    }
  }

}