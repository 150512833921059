.high-map-container {
  background: #000;

  .route {
    a {
      color: #fff !important;
    }

    h1 {
      color: #FFF000 !important;
    }
  }

  .title,
  .page-name {
    color: #fff !important;
  }

  .link {
    color: #FFF000 !important;
  }
}

.map-container {
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;

  .route {
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-top: 30px;

    .back-arrow {
      display: none;
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;
      text-decoration: none;
    }

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #31AEC4;
      text-decoration: none;
      padding-left: 5px;
    }
  }

  .title {
    display: flex;
    padding-left: 100px;
    padding-top: 50px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 144%;

    color: #1B1B1B;
  }

  .links-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 50px;
    padding-top: 50px;
    padding-left: 100px;

    .page-link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .page-name {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 136.18%;
        color: #31AEC4;
      }

      .line {
        width: 514px;
        height: 0px;
        border: 1px solid #D1D1D1;
      }

      p {
        margin-top: 0px;
      }

      .link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 136.18%;
        color: #1B1B1B;
        text-decoration: none;
      }
    }
  }

  @media screen {
    @media (min-width: 600px) and (max-width: 1200px) {
      padding-left: 0px;
      padding-right: 0px;

      .route {
        padding-left: 50px;
      }

      .title {
        padding-left: 50px;
        padding-top: 30px;
      }

      .links-container {
        padding-top: 30px;
        padding-left: 50px;
      }


      .line {
        width: 300px !important;
      }
    }

    @media (max-width: 599px) {
      padding-left: 0px;
      padding-right: 0px;

      .route {
        padding-left: 30px;
      }

      .title {
        padding-left: 30px;
        padding-top: 30px;
      }

      .links-container {
        display: flex;
        flex-direction: column;

        padding-top: 30px;
        padding-left: 30px;
      }


      .line {
        width: 90% !important;
      }

    }
  }
}