.carousel {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding-top: 30px;

  .carousel-container {
    max-width: 750px;
  }

  .carousel-status {
    display: none;
  }

  .thumbs {
    display: none;
  }

  @media screen {
    @media (min-width: 600px) and (max-width: 1200px) {
      .carousel-status {
        display: none;
      }

      .control-dots {
        display: none;
      }
    }

    @media (max-width: 599px) {
      height: 400px;
      padding-right: 80px;

      .carousel-status {
        display: none;
      }

      .control-dots {
        display: none;
      }
    }
  }
}