@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.high-not-found-grid {
  background: #000 !important;

  .text1,
  .text2,
  .text3 {
    color: #fff !important;
  }

  .return-home-button {
    background: #FFFF00 !important;
    color: #000 !important;
  }
}

.not-found-grid {
  background: #fff;
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  @media screen {
    @media (min-width: 1440px) {
      position: initial;
      padding-left: 5%;

      .not-found-text {
        padding-top: 5%;
      }

      .not-found-element {
        padding-left: 100px;

        .not-found-images {
          padding-top: 10%;
        }
      }
    }

    @media (min-width: 600px) and (max-width: 1439px) {
      .not-found-element {
        padding-top: 5%;
      }
    }

    @media (max-width: 599px) {
      position: relative;
      padding-top: 5%;

      .not-found-element {
        margin-top: 0%;
        padding-top: 10%;
      }
    }
  }

  .not-found-element {
    display: grid;
    position: relative;

    .not-found-images {
      position: relative;
      display: grid;
    }
  }

  .not-found-text {
    position: relative;
    display: grid;

    .text1 {
      font-style: normal;
      font-size: 36px;
      font-weight: bolder;
      line-height: 144%;
      align-items: left;
      color: rgba(254, 130, 201, 1);
      vertical-align: center;

      width: 110px;
      height: 52px;

      margin-bottom: 0%;
    }

    .text2 {
      font-size: 200px;
      font-weight: 700;
      font-style: bold;
      color: #FE82C9;
      line-height: 214px;
      align-items: left;
      vertical-align: center;
      height: 214px;
      margin-top: 0%;
      margin-bottom: 0%;
    }

    .text3 {
      font-size: 18px;
      font-weight: bold;
      color: #FE82C9;
      line-height: 25px;
      align-items: right;
      vertical-align: center;
      height: 30px;
      margin-top: 0%;
    }

    a {
      text-decoration: none;
    }

    .return-home-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      gap: 4px;
      height: 40px;
      background: #31AEC4;
      border-radius: 4px;

    }

    .MuiButton-text {
      font-size: 14px;
      font-family: "Open Sans", "sans-serif";
      color: rgba(255, 255, 255, 1);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 100%;
      margin-top: 65px;
    }
  }
}