.informations {
  display: flex;
  flex-direction: row;
  column-gap: 420px;
  align-items: center;

  .pages-filters {
    display: flex;
    align-items: center;
    column-gap: 60px;
  }

  .pagination-container {
    display: flex;
    align-items: flex-start;
  
    .atual-container {
      color:#27486C;
      font-size: 14px;
    }
  
    .pagination {
      display: flex;
      list-style-type: none;
      column-gap: 10px;
      font-size: 14px;
    
      .page-link {
        text-decoration: none;
        color: #27486C;
        cursor: pointer;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  @media (min-width: 1201px) and (max-width: 1600px) {
    column-gap: 300px !important;
  }
}

.none {
  display: flex;
  align-items: center;
  padding-left: 360px;
  font-size: 20px;
}
