.login-container {
  padding-top: 20px;

  .login {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-top: 10px;

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 136.18%;
      color: #525252;
    }

    .input-button {
      width: 300px;
      height: 30px;

      border: 1px solid #525252;
      border-radius: 4px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #D1D1D1;

      padding-left: 10px;
    }

    .keyword {
      display: flex;

      .visibility-button {
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }

  .high-remember {
    color: #fff !important;
  }
}

.close {
  float: right;
  border: none;
  background: none;
  cursor: pointer;
}

.enter-options {
  display: flex;
  column-gap: 90px;
  padding-top: 50px;
  padding-left: 5px;

  .enter-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 12px;
    gap: 4px;
    width: 89px;
    height: 40px;

    background: #31AEC4;
    color: #fff;

    border-radius: 4px;
    border: none;

    cursor: pointer;
  }

  @media screen {
    @media(max-width: 599px) {
      column-gap: 60px;
    }
  }
}

.high-enter-options {
  .forgot {
    background-color: #000 !important;
    color: #fff000;
  }

  .enter-button {
    background-color: #fff000;
    color: #000;
  }
}

.error {
  color: red;
  font-size: 12px;
  padding-left: 8px;
}