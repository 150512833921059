.high-social-footer {
  background: #000 !important;
}

.social-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  padding-top: 25px;
  border-top: 1px solid #D1D1D1;

  .social-logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    column-gap: 40%;
  }
}