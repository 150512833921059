@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.high-page-container {
  background-color: #000 !important;

    .link {
      color: #FFF000 !important;
    }

    .link_atual {
      color: #fff !important;
    }

    .titulo {
      color: #fff !important;
    }

    .subtitulo {
      color: #fff !important;
    }

    .contato-container {
      p{
        color: #fff !important;
      }

      h1 {
        color: #fff !important;
      }
    }
}

.page-container {
  background: #fff;
  .principal-container {
    padding-top: 32px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;

    .link-container {
      font-size: 14px;
      font-family: "Open Sans", "sans-serif";
      font-weight: 400;
      text-align: left;
      padding-bottom: 44px;

      .link {
        text-decoration: none;
        color: #525252;
      }

      .link_atual {
        text-decoration: none;
        color: #31aec4;
      }
      
    }

    .titulo-container {
      text-align: left;
      padding-bottom: 32px;

      .titulo {
        margin: 0px;
        font-family: "Open Sans", "sans-serif";
        font-weight: 700;
        font-size: 36px;
        line-height: 144%;
        color: #31aec4;
      }

      .subtitulo {
        margin: 0px;
        font-family: "Open Sans", "sans-serif";
        font-weight: 400;
        font-size: 14px;
        line-height: 136.18%;
        color: #1b1b1b;
      }
    }

    .contato-container {
      display: flex;
      flex-direction: row;
      gap: 40px;

      .inputs {
        text-align: left;
      }

      .dados {
        padding: 8px;
        text-align: left;
        display: grid;
        flex-direction: column;
        justify-content: baseline;
        .titulo {
          display: flex;
          align-items: center;
        }

        .linha {
          height: 1px;
          background: #d1d1d1;
        }

        img {
          padding: 3px 9px 3px 5px;
        }

        p {
          margin-top: 8px;
          margin-bottom: 0px;
          font-family: "Open Sans";
          font-size: 14px;
          line-height: 136.18%;
          color: #1b1b1b;
        }

        h1 {
          height: 22px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 136.18%;
          color: #31aec4;
        }

        .endereco {
          margin-top: 0px;
          margin-bottom: 8px;
        }

        .email {
          margin-top: 16px;
          margin-bottom: 8px;
        }

        .redes-sociais {
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }
    }
  }

  @media screen {
    @media (min-width: 600px) and (max-width: 1200px) {
      padding-left: 100px;
      .contato-container {
        flex-direction: column !important;
      }
    }

    @media (max-width: 599px) {
      padding-left: 30px;
      .contato-container {
        flex-direction: column !important;
        align-items: flex-start;
      }
    }

  }

}