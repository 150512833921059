.high-publications-container {
  background: #000;

  .principal-container {
    .home-link {
      color: #fff !important;
    }
    .atual-page {
      color: #FFF000 !important;
    }

    .title-container {
      color: #FFF;
    }

    .description {
      color: #fff;
    }
  }

  .none {
    color: #FFF000 !important;
  }

  .labels-container {
    grid-template-rows: repeat(3,430px) !important;
  }

  @media (min-width: 600px) and (max-width: 1200px) {

    .labels-container {
      padding-left: 0px !important;
      gap: 0px !important;
      grid-template-columns: repeat((2, 300px)) !important;
      grid-template-rows: repeat(5, 430px) !important;
    }
  }

  @media (max-width: 600px) {
    .labels-container {
      justify-content: flex-start !important;
      grid-template-rows: repeat(9, 430px) !important;
    }
  }
}

.publications-component {

  .banner {
    background: #FECB3B;
  }

  .publications-container {
    padding-left: 100px;

    .none {
      align-items: center;
      color: #31AEC4;
      padding: 50px 10px 10px 30%;
    }

    .principal-container {
      display: flex;
      flex-direction: column;

      .route {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        padding-top: 30px;

        .home-link,
        .atual-page {
          text-decoration: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 136.182%;
        }

        .home-link {
          color: #525252;
        }

        .atual-page {
          color: #31AEC4;
        }
      }

      .title-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding-right: 50px;

        h1 {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 144%;
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 136.182%;
        }
      }

      .description {
        display: flex;
        padding: 10px 100px 10px 0px;
        text-align: left;
      }

      .filters {
        display: flex;
        align-items: center;
        gap: 30px;
      }
    }

    .labels-container {
      display: grid;
      grid-template-columns: repeat((3, 400px));
      grid-template-rows: 400px;
      justify-content: center;
    }

  }

  @media screen {
    @media (min-width: 1600px) {
      .publications-container {
        padding-left: 100px;

        .pagination {
          display: flex;
          align-items: flex-end;
          padding: 50px 80px 20px 20px;
        }
      }

      .labels-container {
        padding-top: 40px;
        row-gap: 10px;
      }
    }

    @media (min-width: 1201px) and (max-width: 1600px) {
      .publications-container {
        padding-left: 50px;

        .pagination {
          display: flex;
          align-items: flex-end;
          padding: 50px 80px 20px 20px;
        }
      }

      .labels-container {
        gap: 6px;
        padding-top: 40px;
      }
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      .publications-container {
        padding-left: 30px;

        .pagination {
          display: flex;
          align-items: flex-end;
          padding: 50px 80px 20px 20px;
        }

        .title-container {
          gap: 30% !important;
        }
      }

      .labels-container {
        gap: 6px;
        padding-top: 40px;

        grid-template-columns: repeat((2, 380px)) !important;
        grid-template-rows: repeat(5, 450px) !important;
      }
    }


    @media (max-width: 600px) {
      .publications-container {
        padding-left: 0px;
        
        .principal-container {
          align-items: flex-start;
          padding-left: 60px;
        }

        .pagination {
          display: flex;
          align-items: flex-end;
          padding: 50px 20px 20px 20px;
        }

        .title-container {
          flex-direction: column !important;
          gap: 10px !important;
          align-items: flex-start !important;
        }
      }

      .labels-container {
        gap: 6px;
        padding-top: 40px;
        justify-content: center;
        grid-template-columns: repeat((1, 350px)) !important;
      }
    }

  }
}