.high-sobre-container {
  background: #000;

  a,
  p,
  .search-lines,
  .projects,
  .solutions,
  .formations,
  .publications,
  h1 {
    color: #fff !important;
  }

  .route {
    h1 {
      color: #FFFF00 !important;
    }
  }

  .show-more {
    color: #FFFF00 !important;
    margin-top: 30px;
  }

  .high-solutions-box {
    column-gap: 50px !important;
    align-items: center !important;

    .plataform-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 40px 32px;
      gap: 48px;

      border: 2px solid #fff !important;
      border-radius: 4px;

      width: 344px;
      height: 280px;

      .plataforms-button, .simcaq {
        border: solid 2px #FFFF00;
        color: #FFFF00 !important;
      }

    }
  }

  @media (min-width: 1601px) {
    .mapforbr {
      margin-left: 111%;
      margin-top: 50px;
    }
  }

  @media (min-width: 1201px) and (max-width: 1600px) {
    .mapforbr {
      margin-left: 112%;
      margin-top: 50px;
    }
  }

  @media (max-width: 599px) {
    .solutions-box {
      padding-left: 35px !important;
      width: 60% !important;
    }
  }
}

.sobre-container {
  padding-bottom: 50px;

  .route {
    display: flex;
    align-items: center;
    padding-left: 120px;
    padding-top: 30px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #525252;

      text-decoration: none;
    }

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 136.18%;
      color: #31AEC4;

      padding-left: 5px;
    }
  }

  .central-container {
    display: flex;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;
      padding-left: 120px;
      text-align: left;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 136.18%;
        color: #31AEC4;
      }

      p {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 136.18%;
        color: #000;
      }
    }
  }

  .search-lines,
  .projects,
  .solutions,
  .formations,
  .publications {
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 136.18%;
    color: #31AEC4;
  }

  .search-container,
  .formations-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .projects-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 30px 150px 0px 150px;
  }

  .solutions-box {
    display: grid;
    width: 100%;
    align-items: first baseline;

    .plataform-box {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
      width: 65%;

      div {
        align-items: start;
      }
    }

    .plataforms-button {
      text-decoration: none;
      color: #31AEC4;
      font-weight: 400;
      font-size: 14px;

      padding: 8px 5px 8px;
      gap: 4px;
      width: 140px;
      height: 20px;
      border: 2px solid #31AEC4;
      border-radius: 4px;
    }
  }

  .publications-container {
    display: grid;
    grid-template-columns: repeat(3, 370px);
    padding-top: 50px;
    justify-content: center;
  }

  .more-publications {

    .show-more {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      padding-left: 82%;
      padding-top: 50px;
    }

    a {
      font-style: normal;
      font-weight: bolder;
      font-size: 15px;
      line-height: 20px;
      color: #31AEC4;
      text-decoration: none;
    }
  }

  @media screen {
    @media (min-width: 1601px) {

      .back-arrow {
        display: none;
      }

      .central-container {
        padding-left: 30px;
        column-gap: 100px;

        .title {
          width: 40%;
        }
      }

      .search-container {
        column-gap: 80px;
        padding-top: 50px;
      }

      .projects,
      .formations,
      .search-lines,
      .publications,
      .solutions {
        padding-left: 150px;
        padding-top: 100px;
      }

      .solutions-box {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;

        padding-left: 150px;
        padding-top: 80px;
      }

      .mapforbr {
        padding-top: 100px;
        padding-left: 99%;
      }

      .formations-container {
        column-gap: 250px;
        padding-top: 50px;
      }
    }

    @media (min-width: 1201px) and (max-width: 1600px) {

      .back-arrow {
        display: none;
      }

      .central-container {
        padding-left: 10px;
        column-gap: 100px;

        .title {
          width: 35%;
        }
      }

      .search-container {
        column-gap: 60px;
        padding-top: 50px;
      }

      .projects,
      .formations,
      .search-lines,
      .publications,
      .solutions {
        padding-left: 100px;
        padding-top: 100px;
      }

      .projects-container {
        padding: 30px 100px 0px 100px;
      }

      .solutions-box {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;

        padding-left: 150px;
        padding-top: 80px;
      }

      .mapforbr {
        padding-top: 100px;
        padding-left: 99%;
      }

      .formations-container {
        column-gap: 250px;
        padding-top: 50px;
      }

    }

    @media (min-width: 600px) and (max-width: 1200px) {
      .route {
        padding-left: 50px;

        .home-link {
          display: none;
        }
      }

      .central-container {
        padding-left: 15px;
        column-gap: 10px;

        .title {
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          text-align: left;
        }

        img {
          width: 600%;
        }
      }

      .search-container {
        column-gap: 30px;
        padding-top: 40px;

        .search-box {
          width: 20%;
        }
      }

      .projects,
      .formations,
      .search-lines,
      .publications,
      .solutions {
        padding-left: 60px;
        padding-top: 80px;
      }

      .projects-container {
        padding: 30px 60px 0px 60px;
      }

      .solutions-box {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 70px;

        padding-left: 110px;
        padding-top: 80px;
      }

      .formations-container {
        column-gap: 70px;
        padding-top: 50px;
      }


      .publications-container {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 30px;     
      }

      .show-more {
        padding-left: 80% !important;
        padding-top: 40px !important;
      }
    }

    @media (max-width: 599px) {
      .route {
        padding-left: 30px;

        .home-link {
          display: none;
        }
      }

      .central-container {
        flex-direction: column-reverse;
        padding-left: 15px;
        padding-top: 50px;
        row-gap: 30px;

        .title {
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          text-align: left;

          p {
            padding-right: 30px;
          }
        }
      }

      .search-container {
        flex-direction: column;
        row-gap: 30px;
        padding-top: 60px;
      }

      .projects,
      .formations,
      .search-lines,
      .publications,
      .solutions {
        padding-left: 30px;
        padding-top: 50px;
      }

      .projects-container {
        padding: 30px 30px 0px 30px;
      }

      .solutions {
        text-align: left;
      }

      .solutions-box {
        row-gap: 70px;
        padding: 60px 30px 0px 30px;

        .plataform-box {
          width: 100%;
        }
      }

      .formations-container {
        flex-direction: column;
        justify-content: center;
        row-gap: 50px;
        padding-top: 50px;
      }

      .publications-container {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 30px;     
      }

      .show-more {
        padding-left: 55% !important;
        padding-top: 40px !important;
      }
    }
  }
}